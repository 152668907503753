<template>
	<svg
		v-bind="$attrs"
		viewBox="0 0 32 32"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fill-rule="evenodd"
			clip-rule="evenodd"
			d="M25 16C25 16.5523 24.5523 17 24 17L8 17C7.44772 17 7 16.5523 7 16C7 15.4477 7.44772 15 8 15L24 15C24.5523 15 25 15.4477 25 16Z"
		></path>
	</svg>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
	name: "IconMinus",
});
</script>
